import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CCardBody, CCardGroup, CCol, CContainer } from '@coreui/react';

import { AuthClass } from '@/util/auth';
import HeaderDropdown from '@/app/ui/HeaderDropdown';
import TheFooter from '@/app/ui/TheFooter';
import Toaster from '@/components/Toaster';
import { ReactComponent as AtmoTubeLogo } from '@/resources/assets/images/atmo-cube-logo.svg';

import styles from './LayoutAuth.module.css';

function LayoutAuth() {
  const isLoggedIn = useSelector((state) => state.authorization.isLoggedIn);
  const darkMode = useSelector((state) => state.view.darkMode);

  const haveTempToken = AuthClass.checkTempToken();
  const haveToken = AuthClass.checkToken();

  return (
    <React.Fragment>
      {(haveTempToken || isLoggedIn || haveToken) && <HeaderDropdown className={styles.headerDropdown} />}
      <div className="d-flex flex-column w-100 authorization-layout">
        <CContainer
          className="d-flex"
          style={{
            flexBasis: 'auto',
            flexShrink: 0,
            flexGrow: 1,
          }}
        >
          <div className="d-flex justify-content-center auth-container align-self-center w-100">
            <CCol className="col-md-10">
              <CCardGroup>
                <CCol style={{ padding: '30px' }}>
                  <div className={styles.logoWrapper}>
                    <AtmoTubeLogo className={styles.logo} />
                  </div>
                </CCol>
                <CCol style={{ padding: '30px' }}>
                  <CCardBody className={styles.cardBody}>
                    <Outlet />
                  </CCardBody>
                </CCol>
              </CCardGroup>
            </CCol>
          </div>
        </CContainer>
        <TheFooter />
      </div>
      <Toaster/>
    </React.Fragment>
  );
}

export default LayoutAuth;
